<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcRestockingVisits }}</h1>
      <BackJump
        @reload_host="handleFacilityChange()"
        :obj="backJumpObj"
        :vis="true"
        :i18n="translations.components"
      ></BackJump>
    </page-header>

    <page-body class="mx-lg">
      <section class="section-2">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>
              {{ translations.tcRestockingVisits }}
              <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcTooltip">
                <i-tooltip />
              </span>
            </h2>
            <ToggleArrow
              v-if="toggle == true"
              @click="Section2 = !Section2"
              section="Section2"
              :status="Section2"
            ></ToggleArrow>
          </header>
          <b-button
            v-if="Section2 && iCanSee(secured_restocking_visit_add_controls.add_gideoncard_restocking_visit_button)"
            variant="primary"
            class="mr-2 mb-3"
            @click="handleAddVisitClick"
            >{{ translations.tcAddRestockingVisit }}</b-button
          >
          <div v-if="Section2 && translations.components" class="row d-flex events">
            <div class="col col-4 mt-2" v-for="(pres, index) in presentations" :key="index" :obj="pres">
              <app-event
                :key="index"
                :obj="pres"
                :i18n="translations.components"
                @delete_approved="handlePhoneDeleteRequest(pres)"
              ></app-event>
            </div>
            <!-- <p v-if="presentations.length === 0">No restocking visits were found.</p>  tcNoRestockingFound-->
            <p v-if="presentations.length === 0">{{translations.tcNoRestockingFound}}</p>
          </div>
        </div>
      </section>
    </page-body>

    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import dateData from '@/json/date.json'
import BackJump from '@/components/back-jump/BackJumpGideonCards.vue'
import Event from '@/components/Event.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_restocking_visits_list',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      toggleMe: true,
      Section2: true,
      presentations: [],
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_restocking_visit_add_controls: {
        add_gideoncard_restocking_visit_button: '7fdd519a-35d1-4eca-b374-e96e8f05efbe',
      },
    }
  },
  methods: {
    ...mapActions({
      getFacilityRestockingVisits: 'card/getFacilityRestockingVisits',
      clearRestockingVisitDetails: 'card/clearRestockingVisitDetails',
      loadProfile: 'card/getProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
    }),
    async handleAddVisitClick() {
      this.setSelectedVisitKey(null)
      this.clearRestockingVisitDetails()
      this.$router.push('/programs/gc/events/card_restocking_visit_add')
    },
    async handleFacilityChange() {
      // await this.setSelectedFacilityKey(this.userSelectedFacilityKey)
      await this.loadProfile(this.userSelectedFacilityKey).then(() => {
        this.pageLoad()
      })
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getFacilityRestockingVisits(this.userSelectedFacilityKey),
      ]).then(() => {
        this.backJumpObj.location = this.profileSummary.org_name
        this.backJumpObj.link = `/programs/gc/profile/summary/card_profile`
        this.setLoadingStatus(false)
        this.presentations = this.list.map((visit) => {
          let activityDate = new Date(visit.presentation_date)
          let monthName = dateData.months.find((mi) => parseInt(mi.value) === activityDate.getMonth() + 1).abbr
          return {
            name: visit.contacted_by,
            key: visit.mtg_key,
            month: monthName,
            link: `/programs/gc/profile/restocking-visit-details`,
            day: activityDate.getDate(),
            year: activityDate.getFullYear(),
            fullDate: visit.presentation_date,
            meeting: visit.mtg_key,
            facility: this.userSelectedFacilityKey,
          }
        })
      })
    },
    showModal(e, modalName) {
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
  },
  async created() {
    if (!this.profileSummary.gcf_org_key) {
      this.$router.push({ name: 'gideon-card' })
      return
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-gideon-cards', 'quick-jump-gideon-cards', 'event'),
      this.pageLoad(),
    ]).then((results) => {
      const translatedText = { ...results[1] }
      this.$set(this.translations, 'components', translatedText)
    })
  },
  computed: {
    ...mapGetters({
      churchDetail: 'churchMinistry/churchDetail',
      iCanSee: 'user/iCanSee',
      list: 'card/getFacilityRestockingList',
      selectedChurchKey: 'user/userSelectedChurchKey',
      profileSummary: 'card/getFacilitySummary',
      userOrgKey: 'user/userOrgKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {
    appEvent: Event,
    BackJump: BackJump,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    iTooltip: iTooltip,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.service-times-presentations {
  header h1 {
    padding-bottom: 80px;
    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px 20px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
      h2 {
        flex: 1 1 auto;
        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }
      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.events {
  margin-bottom: 50px;
  .col-4 {
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;
      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }
  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}
</style>
