<template>
  <form>
    <b-form-select
      v-model="facilityKey"
      :options="facilitiesForSelection"
      :plain="true"
      @change="handleSelectChange"
      class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0"
    >
      <template slot="first">
        <option :value="null" disabled>{{ i18n['quick-jump-gideon-cards'].tcSelectAnotherFacility }}</option>
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quick-jump-gideon-cards',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump-gideon-cards': {
            tcSelectAnotherFacility: 'Select another facility'
          }
        }
      }
    }
  },
  data() {
    return {
      facilityKey: null
    }
  },
  computed: {
    ...mapGetters({
      facilitiesForSelection: 'card/facilitiesForSelection'
    })
  },
  methods: {
    ...mapActions({
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      getFacilities: 'card/getFacilities'
    }),
    async handleSelectChange() {
      await Promise.all([await this.setSelectedFacilityKey(this.facilityKey)]).then(
        () => {
          this.$emit('facility_changed')
        }
      )
    }
  },
  async created() {
    await this.getFacilities()
  },
}
</script>

<style/>
